.bottom-menu {
    background: #252525;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    border-radius: 1.8em 1.8em 0 0;
    padding: 1em 0;
    overflow: hidden;
    z-index: 100;
}

.bottom-menu.visible {
    transform: translateY(0);
    opacity: 1;
}

.bottom-menu.hidden {
    transform: translateY(100%);
    opacity: 0;
}

.bottom-menu ul {
    display: flex;
    width: 100%;
}

.bottom-menu ul li {
    position: relative;
    list-style: none;
    width: 75px;
    height: 70px;
    z-index: 1;
}

.bottom-menu ul li div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* width: 100%; */
    /* text-align: center; */
    /* font-weight: 500; */
}

.bottom-menu ul li div .icon {
    position: relative;
    /* display: block; */
    /* line-height: 75px; */
    /* font-size: 1.5em; */
    /* text-align: center; */
    transition: 0.5s;
    transform: translateY(-15px);
}

.bottom-menu ul li.active div .icon {
    transform: translateY(-30px) translateX(-5px);
    /* transform: translateY(-30px) translateX(11px); */
}

.bottom-menu ul li div .text {
    position: absolute;
    color: #2272A8;
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

.bottom-menu ul li.active div .text {
    opacity: 1;
    /* transform: translateY(14px) translateX(11px); */

    transform: translateY(1px) translateX(-2px);
}

.indicator {
    position: absolute;
    top: -3%;
    width: 100px;
    height: 100px;
    background: url('../img/Ellipse\ 46.png'); 
    background-size: contain;
    background-repeat: no-repeat; 
    transition: 0.5s;
    left: 1.7em;
}


.indicator::before {
    content: "";
    position: absolute;
    top: 80%;
    left: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 1px -10px 0 0 var(--clr);
}

.indicator::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: -1px -10px 0 0 var(--clr);
}

.bottom-menu ul li:nth-child(1).active~.indicator {
    transform: translateX(calc(75px * 0));
}

.bottom-menu ul li:nth-child(2).active~.indicator {
    transform: translateX(calc(75px * 1));
}

.bottom-menu ul li:nth-child(3).active~.indicator {
    transform: translateX(calc(75px * 2));
}

.bottom-menu ul li:nth-child(4).active~.indicator {
    transform: translateX(calc(75px* 3));
}

@media screen and (max-width: 280px) {

    .bottom-menu ul li {
        width: 60px;
    }

    .bottom-menu ul li.active div .icon {
        transform: translateY(-30px) translateX(3px);
        /* transform: translateY(-30px) translateX(11px); */
    }   
    .bottom-menu ul li.active div .text {
        opacity: 1;
    
        transform: translateY(1px) translateX(3px);
    }

    .bottom-menu ul li:nth-child(1).active~.indicator {
        transform: translateX(calc(60px * 0));
    }
    
    .bottom-menu ul li:nth-child(2).active~.indicator {
        transform: translateX(calc(60px * 1));
    }
    
    .bottom-menu ul li:nth-child(3).active~.indicator {
        transform: translateX(calc(60px * 2));
    }
    
    .bottom-menu ul li:nth-child(4).active~.indicator {
        transform: translateX(calc(60px* 3));
    }
}