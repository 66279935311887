.loaderSection{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 11;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.loaderSecitionh1{
    font-size: 14px;
    margin-top: 20px;
    margin-left: -17px;
}
.lds_ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds_ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }