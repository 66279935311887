@font-face {
  font-family: 'Poppins';
  src: url('./static/fonts/Poppins-Regular.ttf');
}

html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-image: url('./static/img/bg-desktop.avif');
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
}


@media screen and (max-width: 450px){
  body {
      background-image: url('./static/img/bg-mbl.avif');
      background-size: cover;
      background-attachment: fixed;
  }
} 