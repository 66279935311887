.side-menu {
    width: 287px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #252525;
    z-index: 999;
    transition: transform 0.3s ease;
}

.side-menu ul li {
    display: flex;
    gap: 100px;
    position: relative;
    list-style: none;
    z-index: 1;
}

.menu-item {
    display: flex;
    align-items: center; 
}

.active {
    color: #2272A8
}

.menu-item span:hover {
    cursor: pointer;
}
