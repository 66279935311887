.get-started-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100vh;
  background-color: #252525;
}

.image-slider {
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.slide {
  width: 100%;
  transition: transform 0.5s ease;
  object-fit: contain;
}

.get-started-button {
  height: 60px;
  width: 80%;
  max-width: 575px;
  padding: 10px;
  color: white;
  border-radius: 16px;
  border: none;
  background: linear-gradient(95deg, #2272A8 -23.28%, #0B2F5F 109.62%);
  margin: auto;
  margin-bottom: 1em;
}