.hidden {
  display: none;
}

#buffer-element {
  height: 80px;
}

.tab {
  max-width: 100%;
  height: 100%;
  padding: 1em;
}

.podcasts {
  display: flex;
  gap: 8px;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.prof-img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}

.player-info>p,
h2 {
  margin: 0;
}

.unsub-btn {
  border-radius: 16.792px;
  background: linear-gradient(106deg,
      #2272A8  12.63%,
      rgba(97, 117, 130, 0.74) 130%);
  padding: 1em 2em;
  border: none;
  font-weight: 700;
  width: 170px;
  color: rgba(11, 47, 95, 1);
}

#player-bio>p {
  border-radius: 10px;
  margin-bottom: 1em;
  padding: 1em;
  max-width: 100%;
  background: #8181815c;
}

.info-table {
  font-family: url('../fonts/Poppins-Regular.ttf');
}

.info-table table {
  border-collapse: collapse;
  width: 100%;
}

.info-table td {
  padding: 8px;
  text-align: left;
}

.info-table td:first-child {
  white-space: nowrap;
}

.info-table b {
  font-weight: bold;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

.game-tip {
  display: flex;
}

.shorts-tab {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.shorts-video {
  width: 100%;
  height: 100vh; 
  object-fit: contain;
}

.games {
  max-width: 110px;
}

games-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.scrollContainer {
  display: flex;
  gap: 16px;
  overflow-x: scroll;
  scrollbar-width: hidden;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.horizontal-header {
  display: flex;
  align-items: center;
}

.bitez-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

#playBtnSvg {
  display: initial;
  position: absolute;
  z-index: 9;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#playBtnSvg path {
  fill: white;
}

.playText {
  position: absolute;
  text-align: center;
  z-index: 9;
  width: 50px;
  height: 50px;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  align-self: end;
  /* text-shadow: 0px 4px 5px #000, 0 0 5px #000; */
  text-shadow: 0px 3px 0px #110f0e, 0 4px 0px #fff, 0 0 30px #fff0, 0 0 40px #ff00de00, 0 0 70px #ff00de00, 0 0 80px #ff00de00, 0 0 100px #ff00de00, 0 0 150px #ff00de00;
  letter-spacing: 1.2px;
  user-select: none;
}

.shorts-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.playBtnDiv {
  position: fixed;
  display: flex;
  width: 50px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.playIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 80px;
  height: 80px; */
}

/* .playIcon {
  width: 50px;
  height: 50px;
  fill: #ffffff;
} */

.playText {
  color: #ffffff;
  font-size: 18px;
  margin-top: 10px;
}

.likeNshare {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  margin-right: 1em;
  padding-bottom: 2em;
}

.likeNshare p {
  color: #ffffff;
  font-size: 18px;
  margin: 0;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.social-icons img {
  max-height: 42px;
  max-width: 80px;
}

.social-icons img:hover {
  cursor: pointer;
}

/* video::-webkit-media-controls-enclosure {
  width: 60%;
  margin-right: 5px;
  -moz-border-radius: 1px !important;
  -webkit-border-radius: 1px !important;
  border-radius: 1px !important;
  overflow: hidden;
  position: absolute;
  z-index: 10;
}

video::-webkit-media-controls-panel {
  height: 35px !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
} */

.content-active {
  margin-left: 287px;
}

#game-search {
  display: flex;
  border: none;
  width: 70%;
  border-radius: 25px;
  background: #414141;
  padding: 5px;
  padding-right: 1em;
  justify-content: space-between;
}

.input {
  background: #414141;
  border: none;
  border-radius: 15px;
  padding: 1em;
  color: #D9D9D9;
  outline: none; 
  width: 80%
}


#game-search input:focus {
  border: none;
}



#edit-icon {
  position: absolute;
  right: 0;
  margin-right: 2em;
}

.video-container {
  position: relative;
}

.video-container video {
  width: 100%;
}

.custom-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ccc;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #2272A8;
}

@media screen and (max-width: 450px) {
  .podcasts {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

#bbox {
  margin-top: 40px;
  height: 40px;
}

.game-header {
  position: relative;
  overflow: hidden;
  max-height: 193px;
}

.game-header div {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(20.09deg, #000000 -87.76%, rgba(0, 0, 0, 0.25) 25.66%);
  /* backdrop-filter: blur(1px); */
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: end;
  padding: 1.5em;
}

.game-header p {
  font-size: larger;
  font-weight: 700;
  padding-bottom: 1.5em;
}

.game-header img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 2; 
}

@media screen and (min-width: 450px) {
  .game-header {
    max-height: 257px;
  }
}

.shortsContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  /* z-index: -1; */
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  overflow: scroll;
   /*height: 100vh;*/
  height: calc(100vh - 35px);
  scroll-snap-type: y mandatory;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Chrome, Edge, and Safari */
}
  /* For Chrome, Edge, and Safari */
  .shortsContainer::-webkit-scrollbar {
    width: 6px;
  }
  
  .shortsContainer::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .shortsContainer::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
.videoCard_player{
    position: relative;
    width: 100%;
    height: 100%;
    /*height: calc(100vh - 48px);*/
    /* padding-bottom: 45px;*/
    scroll-snap-align: start;
    scroll-snap-stop: always;
    overflow: hidden;
}
.shortsVideoContent {
    width: 100%;
    height: 100%;
    /*height: calc(100vh - 48px);*/
    object-fit: contain;
    /* object-fit: unset; */

    /*object-fit: fill;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*left: 0;*/
}

.slideOutDown {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  height: 150px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0 auto;
  padding: 15px;
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.slideInUp {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  height: 150px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0 auto;
  padding: 15px;
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes slideInUp {
  0% {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  }
  @keyframes slideInUp {
  0% {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  }

.d-flex{
  display: flex;
}
  /* SkeletonLoader.css */
.skeleton {
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #1b2b38, #1a1a1a, #1b2e3b);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading 1.5s infinite;
  /* width: 50px !important;
  height: 50px !important; */
}

@keyframes loading {
  0% {
      background-position: 100% 0;
  }
  100% {
      background-position: -100% 0;
  }
}
